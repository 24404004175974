import { useEffect, useRef, useState } from "react";
import { Button, Rate } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
const HomeCarasoul = () => {
  const [pageData, setPageData] = useState([]);
  const carouselRef = useRef(null);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrevious = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };
  const getCroppedImageStyle = (imageSrc) => {
    const aspectRatio = 16 / 16;
    const modifiedSrc = `${imageSrc}`;
    const width = "100%";
    const height = 720 / aspectRatio;
    return {
      backgroundImage: `url(${modifiedSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center 40%",
      width: `${width}%`,
      height: `${height}px`,
    };
  };
  const getCroppedImageStyleMobile = (imageSrc) => {
    const aspectRatio = 16 / 16;
    const modifiedSrc = `${imageSrc}`;
    const width = "100%";
    const height = 480 / aspectRatio;
    return {
      backgroundImage: `url(${modifiedSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center 40%",
      width: `${width}%`,
      height: `${height}px`,
    };
  };
  const getTestimonialsData = async () => {
    try {
      let config = {
        method: "get",

        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-testimonials?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      };

      const res = await axios.request(config);
      if (res?.data?.data?.length) {
        setPageData(res.data.data);
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    return true;
  };
  useEffect(() => {
    getTestimonialsData();
  }, []);
  return (
    <>
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        itemClass="carousel-item-gap"
        customLeftArrow={<div />}
        customRightArrow={<div />}
        infinite={true}
        draggable={true}
        swipeable={true}
        // autoPlay={true}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container mx-auto"
      >
        {pageData?.map((item, index) => {
          return (
            <div
              className="flex flex-col md:flex-row justify-between h-full gap-5"
              key={item.attributes.name}
            >
              <div className="w-full md:w-[50%] pl-[9%] md:pr-[82px] md:py-[96px] flex flex-col gap-5 justify-between">
                <h1 className="font-semibold text-[30px] md:text-[42px]">
                  {item.attributes.review}
                </h1>
                <div className="flex justify-between items-center">
                  <div>
                    <h1 className="font-semibold text-[18px]">
                      {item.attributes.name}
                    </h1>
                    <h1 className="font-semibold text-[16px] text-[#475467]">
                      {item.attributes.subText}
                    </h1>
                  </div>
                  <div className="flex">
                    <Button
                      onClick={handlePrevious}
                      icon={<ArrowLeftOutlined style={{ fontSize: "20px" }} />}
                      className="bg-white rounded-full p-4 mr-6"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "24px",
                        height: "50px",
                        width: "50px",
                      }}
                    />
                    <Button
                      onClick={handleNext}
                      icon={<ArrowRightOutlined style={{ fontSize: "20px" }} />}
                      className="bg-white rounded-full p-4"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "24px",
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full md:w-[50%] pl-5">
                <div
                  className="hidden md:flex justify-center items-end"
                  style={getCroppedImageStyle(
                    `${item.attributes.image.data.attributes.url}`
                  )}
                >
                  <div className="px-[30px] py-[30px] h-[260px] w-full">
                    <div
                      style={{ border: "1px solid #FFFFFF80" }}
                      className="p-[24px] h-full w-full bg-[#ffffff4d] flex flex-col justify-between items-start"
                    >
                      <div className="w-full flex justify-between items-center">
                        <div className="font-semibold text-[42px] text-[white]">
                          {item.name}
                        </div>
                        <div>
                          <Rate
                            allowHalf
                            value={item.attributes.rating}
                            disabled
                          />
                        </div>
                      </div>
                      <div>
                        <h1 className="font-semibold text-[18px] text-[white]">
                          {item.attributes.role}
                        </h1>
                        <h1 className="font-semibold text-[16px] text-[white]">
                          {item.attributes.organization}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="md:hidden flex justify-center items-end"
                  style={getCroppedImageStyleMobile(
                    `http://localhost:1337${item.attributes.image.data.attributes.url}`
                  )}
                >
                  <div className="px-[20px] py-[20px] h-[220px] w-full">
                    <div
                      style={{ border: "1px solid #FFFFFF80" }}
                      className="p-[14px] h-full w-full bg-[#ffffff4d] flex flex-col justify-between items-start"
                    >
                      <div className="w-full flex flex-col justify-start items-start">
                        <div>
                          <Rate
                            allowHalf
                            value={item.attributes.rating}
                            disabled
                          />
                        </div>
                        <div className="font-semibold text-[32px] text-[white]">
                          {item.attributes.name}
                        </div>
                      </div>
                      <div>
                        <h1 className="font-semibold text-[18px] text-[white]">
                          {item.attributes.role}
                        </h1>
                        <h1 className="font-semibold text-[16px] text-[white]">
                          {item.attributes.organization}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};
export default HomeCarasoul;
